import React, { useState, useEffect } from 'react';
import Routes from './routes';
import GlobalStyle, { Container, Header, Body } from './global';
import api from './service/api';
import { getStorage } from './utils/storage';
import { questions } from './questions';
import { v4 as uuidv4 } from 'uuid';

const App = () => {
  const backup = console.warn;

  console.warn = function filterWarnings(msg) {
    const supressedWarnings = ['The component', 'src\\pages\\'];

    if (!supressedWarnings.some(entry => msg.includes(entry))) {
      backup.apply(console, arguments);
    }
  };

  const corretor = window.location.pathname.split('/')[1];
  const cliente = window.location.pathname.split('/')[2];
  
  const [image, setImage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [enabled, setEnabled] = useState(false);

  let context = getStorage();

  useEffect(()=>{
    async function fetchData(){
      localStorage.clear();
      await handleSearch();
      localStorage.setItem('current_page', `/${corretor}/${cliente}`);
      localStorage.setItem('initial_page', `/${corretor}/${cliente}`);
  
      const resultContext = await handleLocalStorage();    
      if(resultContext !== null){
        localStorage.setItem('cache', JSON.stringify(resultContext)); 
      }
    }    
    fetchData();
  });
  
  useEffect(() => {
    const fetchImage = () => {
        try {
            setImage(`https://storage.googleapis.com/suri-storage-files/banners/${cliente}.png`)
        } catch (err) {}
    }

    fetchImage()
  });


  async function handleLocalStorage(){    
    let formmatedQuestions = questions

    let lastId = 39;

    if (JSON.parse(localStorage.getItem('settings'))['tem_pergunta_generica'] === true) {
      try {
        const response = await api.get(`/api/questions/dinamic/${localStorage.getItem('pesquisa')}`);
    
        if (response.data.length > 0) {
          const questionFatherMap = {};
    
          for (let i = 0; i < response.data.length; i++) {
            const currentFather = response.data[i]["question_father"];
    
            if (currentFather !== null && currentFather !== undefined && currentFather !== '') {
              if (questionFatherMap[currentFather] !== undefined) {
                response.data[i]["question_father"] = questionFatherMap[currentFather];
              } else {
                questionFatherMap[currentFather] = lastId;
                response.data[i]["question_father"] = lastId;
              }
            }
            else{
              delete response.data[i]["question_father"]
            }
    
            lastId += 1;
            response.data[i]["rawId"] = response.data[i]["id"];
            response.data[i]["id"] = lastId;
    
            if (response.data[i]['expected_answer'] && response.data[i]['expected_answer'][0]) {
              response.data[i]['expected_answer'] = String(response.data[i]['expected_answer'][0]).split(';');
            }
          }
    
          formmatedQuestions["Tela 7"] = response.data;
        }
      }
      catch(e){
        setEnabled(true);
      }
    }    

    if (localStorage.getItem('cache') === null) {  
      context = {
        startDate: new Date(),
        corretor:corretor,
        cliente: cliente,
        finalDate: null,
        acceptedTerm: null,
        acceptedTermDate: null,
        questions: formmatedQuestions,
        userData: {
            chave_pessoa: uuidv4(),
            name: '',
            cpf: '',
            phone: '',
            email: '',
        },
        beneficiaries: [],
      }    
    }

    return context
  }

  async function handleSearch() {
    await api
      .get(`/api/search/${corretor}/${cliente}`)
      .then(response => {
        if(response.data.length > 0){     
          const account_info = {
            name: response.data[0].account_name || '-',
            email: response.data[0].account_email || '-',
            phone: response.data[0].account_phone || '-',
          }

          const settings = {
            tem_pergunta_generica: response.data[0].tem_pergunta_generica || false,
          }

          localStorage.setItem('pesquisa', response.data[0].chave_pesquisa);
          localStorage.setItem('somente_titulares', response.data[0].somente_titulares);
          localStorage.setItem('logo', response.data[0].logo);
          localStorage.setItem('texto_lgpd', response.data[0].texto_lgpd);
          localStorage.setItem('texto_inicial', response.data[0].texto_inicial);
          localStorage.setItem('account', JSON.stringify(account_info));
          localStorage.setItem('settings', JSON.stringify(settings));
          localStorage.setItem('mapeamento_anonimizado', response.data[0].mapeamento_anonimizado);

          setLoaded(true);  
        }
        else{ setEnabled(true); }
      }).catch(err => { setEnabled(true); });
  }

  document.title = 'Surimapa';

  return (   
    <Container>
      {
        loaded ?
          <>
            <Header logo={image} />
            <GlobalStyle />      
            <Body>
              <Routes />
            </Body>
          </>
        :
          enabled && 
          <Body>
            <GlobalStyle />  
            <div style={{padding: "40px", textAlign: "center"}}>
              <h3>Mapeamento não habilitado</h3>
              <br />
              <p>Não cadastrado ou fora do período</p>
            </div>   
            </Body>       
        }
    </Container>    
  );
}

export default App;
